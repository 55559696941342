import { Injectable } from '@angular/core';

@Injectable()
export class CacheRegisterService {
  private _cachedPaths: string[] = [];

  public get cachedPaths(): string[] {
    return this._cachedPaths;
  }

  isUrlRegistered(serviceUri: string) {
    const isSubset = this._cachedPaths.some(
      (s) => s.indexOf(serviceUri) > -1 || serviceUri.indexOf(s) > -1,
    );
    return this._cachedPaths.indexOf(serviceUri) > -1 || isSubset;
  }

  addToCache(serviceUri: string) {
    // Check if not already added to list
    if (!this.isUrlRegistered(serviceUri)) {
      this._cachedPaths.push(serviceUri);
    }
  }

  removeFromCache(serviceUri: string) {
    if (this.isUrlRegistered(serviceUri)) {
      const index = this._cachedPaths.indexOf(serviceUri);
      if (index >= 0) {
        this._cachedPaths.splice(index, 1);
      }
    }
  }
}
